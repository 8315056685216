export const labels = {
  title: 'Liv Up',
  metaDescription:
    'Já conhece a Liv Up? Temos várias opções com ingredientes naturais elaboradas por chefs e nutricionistas! ✓Agende sua entrega ✓Alimentação saudável',
  add: 'Adicionar',
  addToCartAlt: 'Adicionar produto à sacola',
  addToCartPlusAlt: 'Adicionar mais 1 item à sacola',
  allergenicsWarning:
    'Com o intuito de aprimorar nossas receitas, alguns produtos podem sofrer alterações. Consulte também as informações nas embalagens.',
  removeToCartAlt: 'Remover 1 item da sacola',
  deleteToCartAlt: 'Excluir produto da sacola',
  description: 'Descrição',
  error404: {
    title: 'Opss, essa página está indisponível.',
    subtitle: 'A página que você está procurando não pode ser encontrada.',
    alt: 'Erro 404',
  },
  error500: {
    title: 'Opss, ocorreu um erro ao carregar esta página.',
    subtitle:
      'Pedimos desculpas, nossa equipe de suporte já foi acionada e em breve este problema será resolvido!',
    alt: 'Erro 500',
  },
  goToMenu: 'Ir para o cardápio',
  slide: {
    altPrev: 'Slide Anterior',
    altNext: 'Próximo Slide',
  },
  seeAll: 'Ver todos',
  kitTitle: 'Kits prontos para facilitar sua escolha',
  kitDescription:
    'Além de preparar pratos gostosos e mandar tudo prontinho, a gente cria um cardápio que combina com o seu gosto.',
  kitListTitle: 'ITENS DESTE KIT',
  emptyPageText: 'Ops, estamos sem itens disponíveis no momento',
  emptyPageButton: 'Ir para página inicial',
  kit: 'Kit',
  kits: 'Kits',
  product: 'Produto',
  meal: 'Prato',
  alter: 'Alterar',
  insideDeliveryArea: 'Eba! Nós estamos aí.',
  outsideDeliveryArea: {
    title:
      'Ainda não entregamos por aí, mas queremos muito! Deixe seu e-mail e a gente te avisa quando chegarmos na sua cidade!',
    inputEmailLabel: 'seu email',
    submittedLabel: 'quero liv up aqui!',
    backToAddress: 'Editar Endereço',
    warnLabel:
      'Nossa lojinha para retirada de pedidos está fechada enquanto durar a quarentena de COVID-19 para a segurança de todos.',
  },
  willDelivery: 'Será que entrega?',
  addressValidation: {
    invalidZipCode: 'CEP inválido',
    invalidNumber: 'Número inválido',
    invalidComplement: 'Complemento inválido',
  },
  zipCodeToDeliver: 'CEP de entrega',
  number: 'Número',
  continue: 'Continuar',
  complement: 'Complemento',
  whereAreYou: 'Conta pra gente',
  tellUs: 'onde é aí mesmo?',
  emailSuccessTexts: {
    title: 'Guardamos seu e-mail com muito carinho.',
    body:
      'Queremos chegar cada vez mais em novas cidades. Torce pra gente conseguir! Quer procurar por um novo endereço? Procure novamente',
    changeAddress: 'clicando aqui.',
  },
  comeBackSoon: 'Volta logo',
  comeBackSoonAlt:
    'Item indisponível temporariamente para o endereço escolhido',
  ingredients: 'Ingredientes',
  nutritionTable: {
    caption: 'Tabela Nutricional',
    infoHighlight: '*Valores diários',
    info:
      'de referência com base em uma dieta de 2000 kcal ou 8400J. Seus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas. (**) VD não estabelecido. (***) Informação Não Disponível no momento',
    columns: ['Total', 'VD*'],
  },
  top: 'Topo',
  backToTop: 'Voltar ao início da página',
  backToOldSite: 'Voltar para a versão antiga',
  help: 'Ajuda',
  askHelp: 'Pedir Ajuda',
  periodNames: {
    breakfast: 'Café da manhã',
    midmorning: 'Lanche da manhã',
    lunch: 'Almoço',
    midafternoon: 'Lanche da tarde',
    dinner: 'Jantar',
    Supper: 'Ceia',
    Other: 'Outros',
  },
  youWillAlsoLike: 'Você também vai gostar',
  grams: 'gramas',
  kcal: 'calorias',
  detailsAbout: 'Detalhes sobre o',
  detailsDay: 'do dia',
  copy: 'Copiar',
  mgm: {
    title: 'Indique a Liv Up e ganhe',
    description: 'Compartilhe seu código com os amigos.',
    descriptionFirstOrder: 'A cada primeiro pedido você ',
    earn: 'ganha ',
    descriptionTheyEarn: 'e eles ganham ',
    descriptionDiscount: ' de desconto',
    buttonAriaLabel: 'Copiar código',
    howItWorks: 'COMO FUNCIONA?',
    percent: 'porcento',
    step1: {
      title: '1. Compartilhe',
      description_1:
        'Envie seu código para um amigo que ainda não comprou com a gente. Ele economiza',
      description_2: 'na primeira compra.',
    },
    step2: {
      title: '2. Ganhe créditos',
      description_1:
        'Depois que ele finalizar a primeira compra aplicando seu código de desconto, você recebe',
      description_2: 'em créditos.',
    },
    step3: {
      title: '3. Utilize seus créditos',
      description:
        'Pronto! Seus créditos já podem ser usados em compras no site ou no app da Liv Up.',
    },
    referralDescription: 'Esses amigos aceitaram seu convite:',
    cardtitle: 'que tal recomendar a Liv Up a um amigo e ganhar',
    share: 'Compartilhe seu código com seus amigos e ganhe',
    onFirstBuy: 'na primeira compra da pessoa indicada:',
  },
  youHave: 'Você possui',
  inCredits: 'em créditos',
  acceptedInvitations: 'convites aceitos',
  yourCredits: 'Seus créditos',
  yourInvitations: 'Seus convites',
  invitationAccepted: 'aceito',
  invitationsAccepted: 'aceitos',
  myPurchases: 'minhas compras',
  myPurchasesSubtitle:
    'Aqui você pode ver o histórico e os detalhes dos seus pedidos; Acompanhar e modificar a data de entrega das suas compras em andamento; E refazer o seu pedido com os itens que você mais amou!',
  order: 'pedido',
  rebuyAriaLabel: 'adicionar itens deste pedido à sacola',
  rebuy: 'adicionar estes itens à sacola',
  approvedOrder: 'Pedido aprovado: ',
  waitingPayment: 'aguardando pagamento: ',
  waitingPaymentOrder: 'Pedido aguardando pagamento: ',
  waitingAuthorization: 'Pedido aguardando autorização: ',
  refusedPayment: 'Pedido recusado: ',
  deliveredOrder: 'Pedido entregue: ',
  deliveryDate: 'Data da entrega: ',
  orderDate: 'Data do pedido: ',
  changeDate: 'Alterar data',
  changeDeliveryDate: 'Alterar data de entrega: ',
  changeDeliveryDateBtn: 'Alterar data de entrega',
  seeBankSlip: 'Ver Boleto',
  month: 'Mês',
  day: 'Dia',
  emptyPurchasesList: 'Você ainda não tem nenhuma compra realizada',
  emptyPurchasesListTitle: 'Nada por aqui',
  inProgress: 'em andamento',
  previousPurchases: 'compras anteriores',
  purchaseValue: ', valor da compra',
  letsGo: 'Vamos lá',
  newLookLivUp: 'Liv Up de Visual Novo',
  newLookLivUpDescription:
    'Nosso site está sendo atualizado pra melhorar sua experiência de compra. Algumas funcionalidades estão indisponíveis e, se preferir, você pode utilizar a versão antiga clicando no botão com o mesmo símbolo acima, no canto inferior direito da sua tela.',
  newLookLivUpAriaLabel:
    'Nosso site está sendo atualizado pra melhorar sua experiência de compra. Algumas funcionalidades estão indisponíveis e, se preferir, você pode utilizar a versão antiga clicando no botão voltar para a versão antiga, no canto inferior direito da sua tela.',
  seeDetails: 'Ver detalhes',
  backToMyPurchases: 'Voltar para Minhas Compras',
  highlights: 'destaques',
  orderDetails: 'Detalhes do seu pedido',
  receiveIn: 'Receber em',
  paymentMethod: 'Forma de pagamento',
  creditCard: 'Cartão de crédito',
  restaurantVoucher: 'Vale refeição',
  marketVoucher: 'Vale alimentação',
  ticketOnline: 'Ticket online',
  bankSlip: 'Boleto',
  offlineDebit: 'Débito offline',
  offlinePayment: 'Pagamento offline',
  yourOrderHas: 'Seu pedido tem',
  breadcrumb: {
    initialPage: 'Página inicial',
    myOrders: 'Minhas compras',
  },
  editProfile: 'Editar Perfil',
  editProfileSubtitle: 'Mantenha suas informações sempre atualizadas.',
  editProfileValidation: {
    invalidName: 'Nome inválido',
    invalidPhone: 'Telefone inválido',
    invalidCpf: 'CPF inválido',
    invalidEmail: 'Email inválido',
  },
  personalData: 'Dados Pessoais',
  fullName: 'Nome Completo',
  phone: 'DDD + Telefone',
  phoneAriaLabel: 'DDD mais telefone',
  cpf: 'CPF',
  email: 'Email',
  saveEditions: 'Salvar alterações',
  confirmChanges: 'Confirmar alteração',
  cancelChanges: 'Cancelar alteração',
  cancel: 'Cancelar',
  successEditProfile: 'Perfil editado com sucesso!',
  errorEditProfile:
    'Não conseguimos salvar seus dados pessoais. Tente novamente ou entre em contato com nosso suporte.',
  currentPassword: 'Senha atual',
  newPassword: 'Nova senha',
  confirmPassword: 'Confirmar senha',
  ageRestriction: {
    title: 'Você tem mais de 18 anos?',
    body:
      'Pra adicionar esse item no seu carrinho, precisamos confirmar a sua idade.\n Beba com moderação.',
    confirmAge: 'Sim, tenho mais de 18 anos',
    denyAge: 'Não, continuar sem o item',
  },
  changePassword: 'Alterar senha',
  wrongPassword: 'Senha incorreta',
  changePasswordError: 'Erro ao atualizar senha',
  preparingMethods: {
    instructions: 'Instruções',
    microwave: {
      title: 'Como preparar nossas refeições no micro-ondas',
      step1: 'Mantenha no freezer em temperatura -18ºC.',
      step2: 'Abra a embalagem no lugar indicado.',
      step3: 'Some os tempos descritos nas embalagens.',
      step4: 'Agora é só abrir, servir e aproveitar a sua refeição.',
    },
    waterBath: {
      title: 'Como preparar nossas refeições em banho-maria',
      step1: 'Mantenha no freezer em temperatura -18ºC.',
      step2: 'Leve ao fogo uma panela com água.',
      step3: 'Quando começar a ferver, coloque as embalagens na água.',
      step4: 'Siga o maior tempo indicado na embalagem.',
      step5: 'Agora é só tirar o saquinho da água, abrir, servir e aproveitar.',
    },
    oven: {
      title: 'Como preparar nossas refeições no forno',
      step1: 'Mantenha no freezer em temperatura -18ºC.',
      step2: 'Preaqueça o forno a 180ºC.',
      step3:
        'Retire o produto da embalagem e coloque em um recipiente próprio para forno.',
      step4: 'Siga o tempo indicado na embalagem.',
      step5: 'Agora é só servir e aproveitar a sua refeição.',
    },
  },
  survey: {
    satisfactionSurvey: 'Pesquisa de satisfação',
    active: {
      title: 'Queremos sua opinião',
    },
    answered: {
      title: 'Valeu por ajudar!',
      subtitle: 'Sua opinião é muito importante :)',
    },
    expired: {
      title: 'Pesquisa encerrada',
      subtitle: 'O prazo para responder esta pesquisa está encerrado.',
    },
    alreadyFilled: {
      title: 'Você já respondeu este formulário!',
      subtitle: 'Agradecemos sua participação.',
    },
    somethingWentWrong: {
      title: 'Opa, algo deu errado :(',
      subtitle: 'Aguarde alguns instantes e tente novamente.',
    },
    loading: {
      title: 'Carregando pesquisa...',
      subtitle: 'Só mais um instante.',
    },
    goBack: 'Ir para a vitrine',
    shouldPublish: 'Podemos postar sua avaliação deste produto?',
    productCommentary: 'Deixe um comentário sobre esse produto',
    productRating: {
      title: 'De 1 a 5, o quanto você gostou desses produtos?',
      one: '1',
      two: '2',
      three: '3',
      four: '4',
      five: '5',
      notConsumed: 'não consumi',
      yes: 'sim',
      no: 'não',
    },
    sendAnswers: 'Enviar respostas',
  },
  addItemsToCartError:
    'Não foi possível adicionar os itens na sacola, tente novamente mais tarde.',
  search: {
    searchingFor: 'Buscando por: ',
    noResultsStart: 'Não encontramos resultado para "',
    noResultsEnd: '".',
    noResultsAdvice:
      'Tente usar um termo mais genérico ou verifique se digitou o termo corretamente.',
    preSearchTitle: 'Você tem fome de quê?',
    mostSearchedTerms: [
      'Frango',
      'Sopa',
      'Arroz',
      'Salada',
      'Quiche',
      'Risoto',
      'Batata',
      'Queijo',
      'Low carb',
      'Banana',
      'Carne',
      'Suco',
      'Tomate',
      'Brócolis',
      'Pizza',
      'Cenoura',
      'Leite',
      'Ravioli',
      'Alface',
      'Peixe',
      'Salmão',
      'Molho',
      'Feijão',
      'Hamburguer',
      'Edamame',
      'Iogurte',
      'Morango',
      'Falafel',
      'Bolinho',
      'Tapioca',
    ],
  },
}
