import { beholder } from './beholder'

export const tiktokTrackerScript = `
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
  )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

    ttq.load("${process.env.TIKTOK_ID}");
    ttq.page();
  }(window, document, 'ttq');
`

const convertToTiktokCurrency = (value: number) => {
  return Number((value / 100).toFixed(2))
}

const convertToTiktokProducts = ({
  items,
  displayCategory,
}: {
  items: Array<{
    sku?: string
    name?: string
    price: number
    quantity?: number
    url?: string
    imageUrl?: string
  }>
  displayCategory?: string
}) =>
  items.map(({ sku, quantity, price, name, url, imageUrl }) => ({
    product_id: sku,
    sku,
    name,
    price: convertToTiktokCurrency(price),
    quantity,
    category: displayCategory,
    url,
    image_url: imageUrl,
  }))

export const tiktokTrackerSetup = () => {
  beholder.watch('addToCart', ({ item, cartSubtotal, action }) => {
    if (action != 'add') {
      return
    }

    window.ttq.instance(process.env.TIKTOK_ID).track('AddToCart', {
      content_category: item.category,
      content_name: item.name,
      content_id: item.id,
      quantity: item.quantity,
      price: convertToTiktokCurrency(item.price),
      currency: 'BRL',
      content_type: 'product',
      value: cartSubtotal && convertToTiktokCurrency(cartSubtotal),
    })
  })

  beholder.watch('setUser', ({ cartItems, cartSubTotal, id }) => {
    const tiktokCartItems = convertToTiktokProducts({ items: cartItems })
    window.ttq.instance(process.env.TIKTOK_ID).track('InitiateCheckout', {
      content_id: id,
      contents: tiktokCartItems,
      value: cartSubTotal && convertToTiktokCurrency(cartSubTotal),
      currency: 'BRL',
      content_type: 'product_group',
    })
  })

  beholder.watch('finished', ({ items, total, orderNumber }) => {
    const tiktokCartItems = convertToTiktokProducts({ items })
    window.ttq.instance(process.env.TIKTOK_ID).track('CompletePayment', {
      content_id: orderNumber,
      value: convertToTiktokCurrency(total),
      content_type: 'product',
      contents: tiktokCartItems,
      currency: 'BRL',
    })
  })
}
