import { generateAddressLabel } from '../helpers/generateAddressLabel'
import { beholder } from './beholder'
import { getFullTrackerName } from './snowplow/logic'

export const snowplowTrackerScript = `
  ;(function(p, l, o, w, i, n, g) {
      if (!p[i]) {
          p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
          p.GlobalSnowplowNamespace.push(i);
          p[i] = function() {
              (p[i].q = p[i].q || []).push(arguments)
          };
          p[i].q = p[i].q || [];
          n = l.createElement(o);
          g = l.getElementsByTagName(o)[0];
          n.async = 1;
          n.src = w;
          g.parentNode.insertBefore(n, g)
      }
  }(window, document, "script", "//storage.googleapis.com/snowplow-files/js_2_10_2.js", "snowplow"));
`

const convertToSnowplowCurrency = (value: number) => {
  return (value / 100).toFixed(2)
}

const snowplowStructEvent = ({
  category,
  action,
  label,
  property,
  value,
}: {
  category: string
  action: string
  label?: string | null
  property?: string | string[] | null
  value?: number | null
}) =>
  window.snowplow('trackStructEvent', category, action, label, property, value)

const snowplowProductLifecycle = (data: {
  product_id: string
  product_name: string
  product_action:
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'impression'
    | 'product_detail_view'
  product_price?: number
  position_in_list?: number
  list?: string
  sorting_version?: string
  label?: string
  property?: string
  value?: number
  experiment_id?: string
  variant_id?: string
  experiment_group?: string
}) =>
  window.snowplow('trackSelfDescribingEvent', {
    schema: 'iglu:br.com.livup/ecommerce_product_lifecycle/jsonschema/4-0-0',
    data,
  })

// Beholder functions to Landing Page
const snowplowTrackerLandingPage = () => {
  beholder.watch('heroCaption', ({ captionIndex, captionName, action }) => {
    snowplowStructEvent({
      category: 'landing_page',
      action: action,
      label: 'hero',
      property: captionName,
      value: captionIndex,
    })
  })
}

// Beholder functions to Checkout
const snowplowTrackerCheckout = () => {
  const trackerName = getFullTrackerName(
    process.env.SNOWPLOW_TRACKER_NAME,
    process.env.VERSION
  )

  // Initial setup
  window.snowplow('newTracker', trackerName, process.env.SNOWPLOW_TRACKER_URL, {
    forceSecureTracker: true,
    appId: 'web',
  })
  window.snowplow('enableActivityTracking', 30, 10)

  // Beholder functions
  beholder.watch('trackPageView', () => {
    window.snowplow('trackPageView')
  })

  beholder.watch('setUser', user => {
    window.snowplow('setUserId', user.id)
  })

  beholder.watch('paymentMethodSelected', payload => {
    snowplowStructEvent({
      category: 'checkout',
      action: 'selected_payment_method',
      label: payload.method,
      property: payload.brand,
    })
  })

  beholder.watch('couponFilled', payload => {
    snowplowStructEvent({
      category: 'checkout',
      action: 'coupon_filled',
      label: payload.status,
      property: payload.nameOfCoupon,
    })

    if (payload.status === 'rejected') {
      snowplowStructEvent({
        category: 'checkout',
        action: 'coupon_rejected',
        label: payload.rejectedReason,
        property: payload.nameOfCoupon,
      })
    }
  })

  beholder.watch('finished', payload => {
    snowplowStructEvent({
      category: 'checkout',
      action: 'step',
      label: 'order_placed',
    })
    snowplowStructEvent({
      category: 'checkout',
      action: 'concluded',
    })
    window.snowplow(
      'addTrans',
      payload.orderNumber,
      'ecommerce',
      convertToSnowplowCurrency(payload.total),
      '0.00',
      convertToSnowplowCurrency(payload.deliveryTax)
    )

    payload.items.forEach(item => {
      window.snowplow(
        'addItem',
        payload.orderNumber,
        item.sku,
        item.name,
        item.kind,
        convertToSnowplowCurrency(item.price),
        item.quantity,
        'BRL'
      )
    })

    window.snowplow('trackTrans')
  })

  beholder.watch('addToCart', payload => {
    const { item, action, list, positionInList } = payload

    snowplowProductLifecycle({
      product_id: item.id,
      product_action: action === 'add' ? 'add_to_cart' : 'remove_from_cart',
      product_name: item.name,
      product_price: item.price,
      position_in_list: positionInList,
      list: list,
    })
  })

  beholder.watch('itemView', payload => {
    snowplowProductLifecycle({
      product_id: payload.id,
      product_action: 'impression',
      product_name: payload.name,
      list: payload.listName,
      position_in_list: payload.positionInList,
      product_price: payload.price,
      label: payload.isAvailable ? 'available' : 'not available',
    })
  })

  beholder.watch('productDetailView', payload => {
    snowplowProductLifecycle({
      product_id: payload.id,
      product_action: 'product_detail_view',
      product_name: payload.name,
      list: payload.listName,
      position_in_list: payload.positionInList,
      product_price: payload.price,
      label: payload.isAvailable ? 'available' : 'not available',
    })
  })
}

// Beholder functions
export const snowplowTrackerSetup = () => {
  snowplowTrackerLandingPage()
  snowplowTrackerCheckout()

  // Use to send Structured Events, search on notion 'Como criar eventos estruturados'
  // Events must be documented in the event dictionary, search on notion 'Events Web - Ecommerce'
  beholder.watch(
    'structuredEvent',
    ({ category, action, label, property, value }) => {
      snowplowStructEvent({
        category,
        action,
        label,
        property,
        value,
      })
    }
  )

  beholder.watch('localizationEmailLead', payload => {
    snowplowStructEvent({
      category: 'localization-email-lead',
      action: 'permission-response',
      label: payload.email,
      property: generateAddressLabel(payload.address),
    })
  })
}
