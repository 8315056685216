import { beholder } from './beholder'

enum PIXEL_BREAKPOINTS {
  MOBILE = 768,
  TABLET = 1024,
  DESKTOP = 1440,
}

const getDeviceType = () => {
  try {
    const { innerWidth } = window

    if (innerWidth <= PIXEL_BREAKPOINTS.MOBILE) return 'm'
    else if (
      innerWidth > PIXEL_BREAKPOINTS.MOBILE &&
      innerWidth <= PIXEL_BREAKPOINTS.TABLET
    )
      return 't'
    return 'd'
  } catch (err) {
    return 'd'
  }
}

export const criteoTrackerScript = `
  window.criteo_q = window.criteo_q || [];
  window.criteo_q.push(
    { event: "setAccount", account: ${process.env.CRITEO_ID} },
    { event: "setSiteType", type: "${getDeviceType()}" },
  );
`

const convertToCriteoCurrency = (value: number) => {
  return (value / 100).toFixed(2)
}

export const criteoTrackerSetup = () => {
  // Beholder functions
  beholder.watch('setUser', user => {
    window.criteo_q.push({ event: 'setEmail', email: user.email })
  })

  beholder.watch('trackPageView', payload => {
    payload.path === '/' &&
      window.criteo_q.push({
        event: 'viewHome',
      })
  })

  beholder.watch('finished', payload => {
    const items = payload.items.map(item => ({
      id: item.sku,
      price: convertToCriteoCurrency(item.price),
      quantity: item.quantity,
    }))

    window.criteo_q.push({
      event: 'trackTransaction',
      id: payload.orderNumber,
      item: items,
    })
  })

  beholder.watch('addToCart', payload => {
    window.criteo_q.push({
      event: 'BasketPage',
      products_info: payload.cartItems,
    })
  })
}
