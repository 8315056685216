import { beholder } from './beholder'

export const segmentTrackerScript = `
  ! function() {
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
      else {
          analytics.invoked = !0;
          analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
          analytics.factory = function(e) {
              return function() {
                  var t = Array.prototype.slice.call(arguments);
                  t.unshift(e);
                  analytics.push(t);
                  return analytics
              }
          };
          for (var e = 0; e < analytics.methods.length; e++) {
              var t = analytics.methods[e];
              analytics[t] = analytics.factory(t)
          }
          analytics.load = function(e, t) {
              var n = document.createElement("script");
              n.type = "text/javascript";
              n.async = !0;
              n.src = "https://cdn.segment.com/analytics.js/v1/" + e + "/analytics.min.js";
              var a = document.getElementsByTagName("script")[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = t
          };
          analytics.SNIPPET_VERSION = "4.1.0";
          analytics.load("${process.env.SEGMENT_ID}");
      }
  }();
`

const convertToSegmentCurrency = (value: number) => {
  return Number((value / 100).toFixed(2))
}

const convertToSegmentProducts = ({
  items,
  displayCategory,
}: {
  items: Array<{
    sku?: string
    name?: string
    price: number
    quantity?: number
    url?: string
    imageUrl?: string
  }>
  displayCategory?: string
}) =>
  items.map(({ sku, quantity, price, name, url, imageUrl }) => ({
    product_id: sku,
    sku,
    name,
    price: convertToSegmentCurrency(price),
    quantity,
    category: displayCategory,
    url,
    image_url: imageUrl,
  }))

export const segmentTrackerSetup = () => {
  // Beholder functions
  beholder.watch(
    'setUser',
    ({ id, email, firstName, lastName, phoneNumber, city, state, country }) => {
      window.analytics.identify(id, {
        id,
        name,
        city,
        state,
        country,
        firstName,
        lastName,
        email,
        phone: phoneNumber,
      })
    }
  )

  beholder.watch(
    'checkoutInitiated',
    ({ numberOfOrders, cartItems, cartSubTotal }) => {
      const segmentCartItems = convertToSegmentProducts({ items: cartItems })

      window.analytics.track('Checkout Started', {
        products: segmentCartItems,
        value: cartSubTotal && convertToSegmentCurrency(cartSubTotal),
        currency: 'BRL',
        new_buyer: numberOfOrders === 0 ? '1' : '0',
      })
    }
  )

  beholder.watch('paymentMethodSelected', ({ method, brand }) => {
    window.analytics.track('Payment Info Entered', {
      payment_method: method,
      brand,
    })
  })

  beholder.watch('couponFilled', ({ status, nameOfCoupon }) => {
    const event = status === 'accepted' ? 'Coupon Applied' : 'Coupon Denied'
    window.analytics.track(event, {
      coupon_name: nameOfCoupon,
    })
  })

  beholder.watch('trackPageView', ({ path }) => {
    window.analytics.page(path)
  })

  beholder.watch(
    'finished',
    ({ orderNumber, items, total, numberOfOrders }) => {
      const segmentCartItems = convertToSegmentProducts({ items })

      const newBuyer = numberOfOrders === 0

      window.analytics.track('Order Completed', {
        order_id: orderNumber,
        eventID: orderNumber.toString(),
        total: convertToSegmentCurrency(total),
        type: 'product',
        products: segmentCartItems,
        currency: 'BRL',
        new_buyer: newBuyer ? '1' : '0',
      })

      window.analytics.track(newBuyer ? 'First Order' : 'Recurring Order', {
        order_id: orderNumber,
        eventID: orderNumber.toString(),
        total: convertToSegmentCurrency(total),
        value: convertToSegmentCurrency(total),
        type: 'product',
        products: segmentCartItems,
        currency: 'BRL',
        new_buyer: newBuyer ? '1' : '0',
      })
    }
  )

  beholder.watch('addToCart', ({ action, item, isFirstBuy }) => {
    const event = action === 'add' ? 'Product Added' : 'Product Removed'

    window.analytics.track(event, {
      product_id: item.id,
      sku: item.id,
      name: item.name,
      category: item.category,
      price: (item.price / 100).toFixed(2),
      currency: 'BRL',
      quantity: item.quantity,
      new_buyer: isFirstBuy ? '1' : '0',
    })
  })

  beholder.watch('cartCreated', ({ item, isFirstBuy }) => {
    window.analytics.track('Cart Created', {
      product_id: item.id,
      sku: item.id,
      name: item.name,
      category: item.category,
      price: (item.price / 100).toFixed(2),
      currency: 'BRL',
      quantity: item.quantity,
      new_buyer: isFirstBuy ? '1' : '0',
    })
  })

  beholder.watch('listView', ({ id, name, items }) => {
    const segmentCartItems = convertToSegmentProducts({
      items,
      displayCategory: name,
    })

    window.analytics.track('Product List Viewed', {
      list_id: id,
      category: name,
      products: segmentCartItems.slice(0, 4),
    })
  })

  beholder.watch(
    'productDetailView',
    ({ id, name, listName, positionInList, price, isAvailable }) => {
      window.analytics.track('Product Viewed', {
        product_id: id,
        product_name: name,
        list: listName,
        position_in_list: positionInList,
        product_price: convertToSegmentCurrency(price),
        label: isAvailable ? '1' : '0',
      })
    }
  )
}
