import { useEffect } from 'react'
import { captureException } from '@lojinha/sentry'
import { beholder } from './beholder'

import { partnerTagManagerScript } from './partnerTagManager'
import { tagManagerScript, tagManagerTrackSetup } from './tagManager'
import { snowplowTrackerScript, snowplowTrackerSetup } from './snowplow'
import { segmentTrackerScript, segmentTrackerSetup } from './segment'
import { criteoTrackerScript, criteoTrackerSetup } from './criteo'
import { getSiteControlTrackerSetup } from './getSiteControl'
import { tiktokTrackerScript, tiktokTrackerSetup } from './tiktok'
import { googleAnalyticsScript } from './googleAnalytics'

const initializedTrackers: string[] = []

const setupTracker = (name: string, setupFunc: () => void) => {
  if (initializedTrackers.includes(name)) {
    return
  }
  initializedTrackers.push(name)
  try {
    setupFunc()
  } catch (error) {
    captureException(error)
  }
}

const beholderSetup = () => {
  setupTracker('tiktok', () => tiktokTrackerSetup())
  setupTracker('snowplow', () => snowplowTrackerSetup())
  setupTracker('segment', () => segmentTrackerSetup())
  setupTracker('criteo', () => criteoTrackerSetup())
  setupTracker('tagManager', () => tagManagerTrackSetup())
  setupTracker('getSiteControl', () => getSiteControlTrackerSetup())
}

const scripts = [
  {
    id: 'script-snowplow',
    innerHTML: snowplowTrackerScript,
  },
  {
    id: 'script-tagmanager',
    innerHTML: tagManagerScript,
  },
  {
    id: 'script-partner-tagmanager',
    innerHTML: partnerTagManagerScript,
  },
  {
    id: 'script-gtag',
    src: `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID}`,
  },
  {
    id: 'script-ganalytics',
    innerHTML: googleAnalyticsScript,
  },
  {
    id: 'script-tiktok',
    innerHTML: tiktokTrackerScript,
  },
  {
    id: 'script-segment',
    innerHTML: segmentTrackerScript,
  },
  {
    id: 'script-criteo-lib',
    src: '//static.criteo.net/js/ld/ld.js',
  },
  {
    id: 'script-criteo',
    innerHTML: criteoTrackerScript,
  },
  {
    id: 'script-get-site-control',
    src: '//l.getsitecontrol.com/l465197m.js',
  },
]

export const Trackers = () => {
  const disableTrackers = () => {
    if (window.location.search.includes('disable-trackers=true')) {
      sessionStorage.setItem('disable-trackers', 'true')
    }

    if (sessionStorage.getItem('disable-trackers') === 'true') {
      return true
    }

    return false
  }

  useEffect(() => {
    if (disableTrackers()) return

    scripts.forEach(script => {
      if (document.getElementById(script.id)) {
        return
      }

      const el = document.createElement('script')
      el.type = 'text/javascript'
      el.id = script.id
      if (script.innerHTML) {
        el.innerHTML = script.innerHTML
      }
      if (script.src) {
        el.src = script.src
        el.async = true
      }
      document.getElementsByTagName('head')[0].appendChild(el)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (disableTrackers()) return
    beholderSetup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fix first page view event
  useEffect(() => {
    beholder.shot('trackPageView', {
      path: window.location.pathname,
    })
  }, [])

  return null
}
