import { beholder } from './beholder'

export const getSiteControlTrackerSetup = () => {
  // Beholder functions
  beholder.watch(
    'setUser',
    ({
      id,
      email,
      firstName,
      lastName,
      phoneNumber,
      city,
      state,
      country,
      numberOfOrders,
      insideLoyaltyProgram,
      loyaltyTierId,
    }) => {
      window.gsc('params', {
        id,
        city,
        state,
        country,
        firstName,
        lastName,
        email,
        phoneNumber,
        numberOfOrders: `${numberOfOrders}`,
        insideLoyaltyProgram: `${insideLoyaltyProgram}`,
        loyaltyTierId: `${loyaltyTierId}`,
      })
    }
  )
}
