import { AddressKind, PaymentMethodType } from '@lojinha/palantir'
import { useRouter } from 'next/router'
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Address,
  BagFeedbacks,
  BagProps,
  CheckoutVisitor,
  Coupon,
  DeliveryDate,
  LocalizationModal,
  MacroPaymentMethod,
  SavedCard,
  User,
  VoucherMethod,
} from './types'

type ContextData = {
  isChatLoading?: boolean
  isContextLoaded?: boolean
  isAuthenticated: boolean
  deliveryKind?: AddressKind
  centerId?: string
  address?: Address
  deliveryDate?: DeliveryDate
  coupon?: Coupon
  card?: SavedCard
  savedCards?: SavedCard[]
  selectedMacroPaymentMethod?: MacroPaymentMethod
  selectedVoucherBrand?: VoucherMethod
  selectedPaymentMethod?: PaymentMethodType
  deliveryTax?: number
  isBagOpen?: boolean
  doormanAllowed?: boolean
  stepsOrder?: CheckoutVisitor
  mundipaggIsOffline?: boolean
  bag?: BagProps
  discount?: number
  isComponentLoading?: boolean
  cartId?: string
  localizationModal?: LocalizationModal
  isLivupper?: boolean
  freeShippingValue?: number
  referralRewardAmount?: number
  allAddresses?: Address[]
  routeAfterLogin?: string
  ageRestrictionModal?: boolean
  callbackAgeRestriction?: () => void
  isFirstBuy?: boolean
  bagFeedback?: BagFeedbacks
  removedItems?: string[]
  user?: User
  isSearchOpen?: boolean
  searchTerm?: string
  userAgent?: string
  isMobileUserAgent?: boolean
}

type ContextFunctions = {
  clearContext: () => void
  setIsAuthenticated: (isAuthenticated: boolean) => void
  clearAddress: () => void
  setDeliveryKind: (deliveryKind: AddressKind) => void
  setCenterId: (centerId: string) => void
  setIsContextLoaded: (isLoaded: boolean) => void
  setChatLoading: (isChatLoading: boolean) => void
  setAddress: (address: Address) => void
  setDeliveryDate: (date?: DeliveryDate) => void
  setCoupon: (coupon?: Coupon) => void
  setCard: (card: SavedCard) => void
  setSelectedMacroPaymentMethod: (
    selectedMacroPaymentMethod: MacroPaymentMethod
  ) => void
  setSelectedVoucherBrand: (selectedVoucherBrand: VoucherMethod) => void
  setSelectedPaymentMethod: (selectedPaymentMethod: PaymentMethodType) => void
  setDeliveryTax: (deliveryTax: number) => void
  setBagOpen: (isBagOpen: boolean) => void
  setDoormanAllowed: (doormanAllowed: boolean) => void
  setStepsOrder: (stepsOrder: CheckoutVisitor) => void
  setMundipaggIsOffline: (mundipaggIsOffline: boolean) => void
  setSavedCards: (cards: SavedCard[]) => void
  setBag: (bag: BagProps) => void
  setDiscount: (discount: number) => void
  setIsComponentLoading: (isComponentLoading: boolean) => void
  setCartId: (cartId?: string) => void
  setLocalizationModal: (localizationModal?: LocalizationModal) => void
  setIsLivupper: (isLivupper?: boolean) => void
  setIsFirstBuy: (isFirstBuy: boolean) => void
  setFreeShippingValue: (freeShippingValue: number) => void
  setReferralRewardAmount: (referralRewardAmount?: number) => void
  setAllAddresses: (allAddresses: Address[]) => void
  setRouteAfterLogin: (route: string) => void
  setAgeRestrictionModal: (ageRestrictionModal?: boolean) => void
  setCallbackAgeRestriction: (callbackAgeRestriction: () => void) => void
  setBagFeedback: (bagFeedback?: BagFeedbacks) => void
  setRemovedItems: (removedItems?: string[]) => void
  setUser: (name: string, email: string) => void
  setUserAgent: (userAgent: string) => void
  setIsMobileUserAgent: (isMobileUserAgent: boolean) => void
}

export type ContextValue = ContextData & ContextFunctions

export const Context = createContext<ContextValue>({} as any)

export const ContextProvider: FC<ContextData> = props => {
  const [data, setData] = useState<ContextData>({
    ...props,
  })

  const { pathname } = useRouter()

  useEffect(() => {
    setData(prev => ({ ...prev, isBagOpen: false }))
  }, [pathname])

  const clearContext = () => {
    setData({
      isChatLoading: false,
      isContextLoaded: false,
      isAuthenticated: false,
      deliveryKind: undefined,
      centerId: undefined,
      address: undefined,
      deliveryDate: undefined,
      coupon: undefined,
      card: undefined,
      savedCards: undefined,
      selectedMacroPaymentMethod: undefined,
      selectedVoucherBrand: undefined,
      selectedPaymentMethod: undefined,
      deliveryTax: undefined,
      isBagOpen: false,
      doormanAllowed: false,
      stepsOrder: undefined,
      mundipaggIsOffline: false,
      bag: undefined,
      discount: undefined,
      isComponentLoading: false,
      cartId: undefined,
      localizationModal: undefined,
      isLivupper: false,
      referralRewardAmount: undefined,
      ageRestrictionModal: false,
      callbackAgeRestriction: undefined,
      isFirstBuy: true,
      bagFeedback: undefined,
      removedItems: undefined,
      user: undefined,
    })
  }

  const setBagOpen = (isBagOpen: boolean) =>
    setData(prev => ({ ...prev, isBagOpen }))

  const setDeliveryKind = (deliveryKind: AddressKind) =>
    setData(prev => ({ ...prev, deliveryKind }))

  const clearAddress = () =>
    setData(prev => ({ ...prev, address: undefined, deliveryTax: undefined }))

  const setCenterId = (centerId: string) =>
    setData(prev => ({ ...prev, centerId }))

  const setAddress = (address: Address) =>
    setData(prev => ({ ...prev, address }))

  const setDeliveryDate = (deliveryDate?: DeliveryDate) =>
    setData(prev => ({ ...prev, deliveryDate }))

  const setCoupon = (coupon?: Coupon) => setData(prev => ({ ...prev, coupon }))

  const setSelectedMacroPaymentMethod = (
    selectedMacroPaymentMethod: MacroPaymentMethod
  ) => setData(prev => ({ ...prev, selectedMacroPaymentMethod }))

  const setSelectedVoucherBrand = (selectedVoucherBrand: VoucherMethod) =>
    setData(prev => ({ ...prev, selectedVoucherBrand }))

  const setCard = (card: SavedCard) => setData(prev => ({ ...prev, card }))

  const setSelectedPaymentMethod = (selectedPaymentMethod: PaymentMethodType) =>
    setData(prev => ({ ...prev, selectedPaymentMethod }))

  const setDeliveryTax = (deliveryTax: number) =>
    setData(prev => ({ ...prev, deliveryTax }))

  const setDoormanAllowed = (doormanAllowed: boolean) =>
    setData(prev => ({ ...prev, doormanAllowed }))

  const setIsAuthenticated = (isAuthenticated: boolean) =>
    setData(prev => ({ ...prev, isAuthenticated }))

  const setStepsOrder = (stepsOrder: CheckoutVisitor) =>
    setData(prev => ({ ...prev, stepsOrder }))

  const setMundipaggIsOffline = (mundipaggIsOffline: boolean) =>
    setData(prev => ({ ...prev, mundipaggIsOffline }))

  const setSavedCards = (savedCards: SavedCard[]) =>
    setData(prev => ({ ...prev, savedCards }))

  const setBag = (bag: BagProps) => setData(prev => ({ ...prev, bag }))

  const setDiscount = (discount: number) =>
    setData(prev => ({ ...prev, discount }))

  const setIsComponentLoading = (isComponentLoading: boolean) =>
    setData(prev => ({ ...prev, isComponentLoading }))

  const setCartId = (cartId?: string) => setData(prev => ({ ...prev, cartId }))

  const setLocalizationModal = (localizationModal?: LocalizationModal) =>
    setData(prev => ({ ...prev, localizationModal }))

  const setIsContextLoaded = (isContextLoaded: boolean) =>
    setData(prev => ({ ...prev, isContextLoaded }))

  const setChatLoading = (isChatLoading: boolean) =>
    setData(prev => ({ ...prev, isChatLoading }))

  const setIsLivupper = (isLivupper?: boolean) =>
    setData(prev => ({ ...prev, isLivupper }))

  const setIsFirstBuy = (isFirstBuy: boolean) =>
    setData(prev => ({ ...prev, isFirstBuy }))

  const setFreeShippingValue = (freeShippingValue: number) =>
    setData(prev => ({ ...prev, freeShippingValue }))

  const setReferralRewardAmount = (referralRewardAmount?: number) =>
    setData(prev => ({ ...prev, referralRewardAmount }))

  const setAllAddresses = (allAddresses: Address[]) =>
    setData(prev => ({ ...prev, allAddresses }))

  const setRouteAfterLogin = (routeAfterLogin: string) =>
    setData(prev => ({ ...prev, routeAfterLogin }))

  const setAgeRestrictionModal = (ageRestrictionModal?: boolean) =>
    setData(prev => ({ ...prev, ageRestrictionModal }))

  const setCallbackAgeRestriction = (callbackAgeRestriction: () => void) =>
    setData(prev => ({ ...prev, callbackAgeRestriction }))

  const setBagFeedback = (bagFeedback?: BagFeedbacks) =>
    setData(prev => ({ ...prev, bagFeedback }))

  const setRemovedItems = (removedItems?: string[]) =>
    setData(prev => ({ ...prev, removedItems }))

  const setUser = (name: string, email: string) =>
    setData(prev => ({ ...prev, user: { name, email } }))

  const setUserAgent = (userAgent: string) =>
    setData(prev => ({ ...prev, userAgent }))

  const setIsMobileUserAgent = (isMobileUserAgent: boolean) =>
    setData(prev => ({ ...prev, isMobileUserAgent }))

  return (
    <Context.Provider
      value={{
        ...data,
        clearContext,
        clearAddress,
        setChatLoading,
        setDeliveryKind,
        setCenterId,
        setIsContextLoaded,
        setAddress,
        setDeliveryDate,
        setCoupon,
        setSelectedMacroPaymentMethod,
        setSelectedVoucherBrand,
        setCard,
        setSelectedPaymentMethod,
        setDeliveryTax,
        setBagOpen,
        setDoormanAllowed,
        setIsAuthenticated,
        setStepsOrder,
        setMundipaggIsOffline,
        setSavedCards,
        setBag,
        setDiscount,
        setIsComponentLoading,
        setCartId,
        setLocalizationModal,
        setIsLivupper,
        setIsFirstBuy,
        setFreeShippingValue,
        setReferralRewardAmount,
        setAllAddresses,
        setRouteAfterLogin,
        setAgeRestrictionModal,
        setCallbackAgeRestriction,
        setBagFeedback,
        setRemovedItems,
        setUser,
        setUserAgent,
        setIsMobileUserAgent,
      }}
      {...props}
    />
  )
}

export const useLojinhaContext = (): ContextValue => {
  return useContext(Context)
}
