import { ItemTag, ItemTagsContent } from '@lojinha/palantir'
import dayjs from 'dayjs'
import 'dayjs/locale/pt'
import { t } from '../dictionary'
import {
  numberToCurrency,
  numberToCurrencyAriaLabel,
} from '../helpers/formatNumber'
import { labels } from './pt-labels'

type Address = {
  street?: string
  number?: string | null
  neighborhood?: string
  city?: string
  state?: string
}

type DeliveryDatePeriod = {
  name: string
  timeRange: number[]
}

const months: any = {
  Jan: 'janeiro',
  Fev: 'fevereiro',
  Mar: 'março',
  Abr: 'abril',
  Mai: 'mario',
  Jun: 'junho',
  Jul: 'julho',
  Ago: 'agosto',
  Set: 'setembro',
  Out: 'outubro',
  Nov: 'novembro',
  Dez: 'dezembro',
}

export const itemTagsContentTranslate = {
  [ItemTagsContent.IsNew]: 'Novo',
  [ItemTagsContent.IsBestSeller]: 'mais vendidos',
  [ItemTagsContent.SustainableBeef]: 'carne sustentável',
  [ItemTagsContent.SustainableChicken]: 'frango sustentável',
  [ItemTagsContent.SustainableFish]: 'peixe sustentável',
  [ItemTagsContent.ContainsOrganic]: 'contém orgânicos',
  [ItemTagsContent.OrganicProduct]: '100% orgânico',
  [ItemTagsContent.ProductWithOrganicIngredients]:
    'contém ingredientes orgânicos',
  [ItemTagsContent.Gluten]: 'contém glúten',
  [ItemTagsContent.Lactose]: 'contém lactose',
  [ItemTagsContent.Nuts]: 'contém nozes',
  [ItemTagsContent.Eggs]: 'contém ovos',
  [ItemTagsContent.NoSugar]: 'sem açúcar',
  [ItemTagsContent.Vegan]: 'vegano',
  [ItemTagsContent.Vegetarian]: 'vegetariano',
  [ItemTagsContent.SweetenedWithFruit]: 'adoçado com fruta',
  [ItemTagsContent.AllFruit]: '100% fruta',
  [ItemTagsContent.NaturalSweeteners]: 'adoçantes naturais',
  [ItemTagsContent.ColdPressed]: 'prensado a frio',
  [ItemTagsContent.Refrigerated]: 'refrigerado',
  [ItemTagsContent.OnlyVegetables]: 'só vegetais',
}

export const translatedPaymentMethod = {
  BOLETO: labels.bankSlip,
  CREDIT_CARD: labels.creditCard,
  RESTAURANT_VOUCHER_OFFLINE: labels.restaurantVoucher,
  RESTAURANT_VOUCHER_ONLINE: labels.restaurantVoucher,
  MARKET_VOUCHER_OFFLINE: labels.marketVoucher,
  MARKET_VOUCHER_ONLINE: labels.marketVoucher,
  VOUCHER: labels.restaurantVoucher,
  VOUCHER_OFFLINE: labels.restaurantVoucher,
  PAYMENT_OFFLINE: labels.offlinePayment,
  DEBIT_OFFLINE: labels.offlineDebit,
  UNKNOWN: labels.offlineDebit,
  TICKET_ONLINE: labels.ticketOnline,
}

const badgesAriaLabel = ({ badges }: { badges?: ItemTag[] }) => {
  if (!badges || badges?.length === 0) return ''

  return badges?.reduce(
    (acc, badge) => `${acc} ${itemTagsContentTranslate[badge.content]},`,
    'Tags:'
  )
}

export const formatters = {
  slideCountLabel: (index: number, total: number) => `${index} de ${total}`,

  singleAddress: (address: Address) =>
    `${address.street}, ${address.number} - ${address.neighborhood}, ${address.city} - ${address.state}`,
  productsCount: (count: number) =>
    count === 1 ? `${count} item` : `${count} itens`,

  yourBagCountAriaLabel: (count: number) =>
    `Sua sacola tem ${count === 1 ? `${count} item` : `${count} itens`}`,

  priceAriaLabel: ({
    price,
    priceFrom,
  }: {
    price: number
    priceFrom?: number | null
  }) =>
    `Preço ${
      !!priceFrom && priceFrom > price
        ? `de ${numberToCurrencyAriaLabel(priceFrom)} por `
        : ''
    }${numberToCurrencyAriaLabel(price)}`,

  weightAriaLabel: (weight: string) => `Peso ${weight}.`,

  weightAndUnit: (
    weight: number | null | undefined,
    unit: string | null | undefined
  ) => `${weight}${unit || ''}`,

  quantity: (n: number) => `${n} unidade${n === 1 ? '' : 's'}`,

  quantityAriaLabel: (quantity: number) => `Quantidade ${quantity}`,

  productCardAriaLabel: ({
    title,
    description,
    price,
    priceFrom,
    weight,
    badges,
  }: {
    title: string
    description?: string
    price: number
    priceFrom?: number
    weight: string
    badges?: ItemTag[]
  }) =>
    `${title}, ${description ? `e ${description},` : ''} ${t.priceAriaLabel({
      price,
      priceFrom,
    })}, ${t.weightAriaLabel(weight)}, ${badgesAriaLabel({
      badges,
    })}. Clique para ver mais detalhes.`,

  lastCardText: (displayName: string) =>
    `Veja todas as opções de ${displayName}`,

  pricePerDayLabel: (pricePerDay: number) =>
    `${numberToCurrency(pricePerDay)}/dia`,

  pricePerDayAriaLabel: (pricePerDay: number) =>
    `Preço por dia ${numberToCurrency(pricePerDay)}`,

  descriptionFormatted: (description?: string) =>
    description ? `e ${description},` : '',

  productKitCardAriaLabel: ({
    title,
    description,
    price,
    priceFrom,
    pricePerDay,
    badges,
  }: {
    title: string
    description?: string
    price: number
    priceFrom?: number
    pricePerDay: number
    badges?: ItemTag[]
  }) =>
    `${title}, ${t.descriptionFormatted(description)} ${t.priceAriaLabel({
      price,
      priceFrom,
    })}, ${t.pricePerDayAriaLabel(pricePerDay)}, ${badgesAriaLabel({
      badges,
    })}. Clique para ver mais detalhes.`,

  bagItemAriaLabel: ({
    title,
    description,
    price,
    priceFrom,
    quantity,
  }: {
    title: string
    description?: string
    price: number
    priceFrom?: number | null
    quantity: number
  }) =>
    `${title}, ${t.descriptionFormatted(description)} ${t.priceAriaLabel({
      price,
      priceFrom,
    })}, ${t.quantityAriaLabel(quantity)}`,

  freeDeliveryAmountNeededAriaLabel: ({
    amountNeeded,
    total,
  }: {
    amountNeeded: number
    total: number
  }) => `Faltam ${numberToCurrency(amountNeeded - total)} para o frete grátis.`,

  totalValue: (total: number) => `Total: ${numberToCurrency(total)}.`,

  temporaryUnavailable: (total: number) =>
    `${total > 1 ? 'Produtos' : 'Produto'} temporariamente ${
      total > 1 ? 'indisponíveis' : 'indisponível'
    }`,

  ariaTemporaryUnavailable: (total: number) =>
    `${total > 1 ? 'Lista de produtos' : 'Produto'} ${
      total > 1 ? 'indisponíveis' : 'indisponível'
    }`,

  temporaryUnavailableTitle: (total: number) =>
    `${
      total > 1 ? 'Esses produtos fizeram' : 'Esse produto fez'
    } muito sucesso por aí - levaram tudo!`,

  temporaryUnavailableDescription: (total: number) =>
    `Mas fique de olho que logo ${
      total > 1 ? 'eles voltam' : 'ele volta'
    } para o site.`,

  allergenics: (content: string) => `Alérgicos: ${content.toLocaleLowerCase()}`,

  nutritionTablePortion: ({ size, unit }: { size: string; unit: string }) => {
    const hasEmptyParams = size.length === 0 || unit.length === 0

    return hasEmptyParams ? '' : `Porção de ${parseFloat(size)}${unit}`
  },

  date: {
    formatDateToUTC: (dateString: string) => {
      const date = new Date(dateString)
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
      )
    },

    deliveryMonth: (date: Date) => dayjs(date).locale('pt').format('MMM'),

    deliveryInterval: (timeRange: number[]) =>
      `${timeRange[0]}h às ${timeRange[1]}h`,

    deliveryPeriodInterval: (period: DeliveryDatePeriod) => {
      const interval = t.date.deliveryInterval(period.timeRange)
      return `Pela ${period?.name}, das ${interval}`
    },

    deliveryDayOfWeekAndDate: (date: Date) => {
      const dateLocale = dayjs(date).locale('pt')
      const day = dateLocale.format('dddd').split('-')[0]
      const dateWithDayMonthYear = dateLocale
        .format('D [de] MMMM [de] YYYY')
        .toString()

      return `${day}, ${dateWithDayMonthYear}`
    },

    fullyDeliveryDate: (deliveryDate: {
      date: Date
      period: {
        name: string
        timeRange: number[]
      }
    }) => {
      const { date, period } = deliveryDate
      const { deliveryDayOfWeekAndDate, deliveryPeriodInterval } = t.date

      return `${deliveryDayOfWeekAndDate(date)} · ${deliveryPeriodInterval(
        period
      )}`
    },

    dayCalendarAriaLabel: ({ day, month }: { day: number; month?: string }) => {
      return month
        ? `${t.month}: ${months[month]}. ${t.day} ${day}`
        : `${t.day} ${day}`
    },
  },

  searchItemsCount: (count: number) => {
    return count === 1 ? '1 item encontrado' : `${count} itens encontrados`
  },
}
